<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.path"
        :href="'#' + tab.path"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item value="account">
        <account-settings />
      </v-tab-item>

      <v-tab-item value="security">
        <security-settings />
      </v-tab-item>

      <v-tab-item value="transaction-category">
        <transaction-category-settings />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiApplicationCog, mdiInformationOutline, mdiAccountMultiple, mdiAdjust,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import AccountSettings from './AccountSettings.vue'
import SecuritySettings from './SecuritySettings.vue'
import TransactionCategorySettings from './TransactionCategorySettings.vue'

export default {
  components: {
    AccountSettings,
    SecuritySettings,
    TransactionCategorySettings,
  },
  setup() {
    // tabs
    const tabs = [
      { title: 'Account', path: 'account', icon: mdiAccountOutline },
      { title: 'Security', path: 'security', icon: mdiLockOpenOutline },
      { title: 'Category Settings', path: 'transaction-category', icon: mdiApplicationCog },
    ]

    return {
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiAccountMultiple,
        mdiAdjust,
      },
    }
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get() {
        return this.$route.query.tab
      },
    },
  },
}
</script>
