<template>
  <div>
    <div
      v-for="(width, index) in randomWidths"
      :key="index"
    >
      <vue-skeleton-loader
        class="mt-6 ml-6"
        :width="width"
        :height="20"
      />
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  components: {
    VueSkeletonLoader,
  },
  data() {
    return {
      randomWidths: [],
    }
  },
  mounted() {
    const minWidth = 200
    const maxWidth = 400
    for (let i = 0; i < 8; i++) {
      const width = Math.floor(Math.random() * (maxWidth - minWidth + 1) + minWidth)
      this.randomWidths.push(width)
    }
  },

}
</script>
