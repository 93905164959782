<template>
  <v-card
    flat
    class="mt-5"
  >
    <div class="px-3">
      <h1 class="text-center">
        Category Settings
      </h1>
      <transaction-category-setting-shimmer
        v-if="isTransactionCategoriesLoading"
      />
      <v-treeview
        v-else
        :items="categories"
        :open.sync="open"
        item-children="transaction_sub_categories"
        class="mt-5"
        item-key="id"
        activatable
        open-on-click
      >
        <template #prepend="{ item }">
          <v-btn
            v-if="editing === item.id"
            text
            icon
            @click="cancelEdit(item)"
          >
            <v-icon>
              {{ icons.mdiCancel }}
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            text
            icon
            @click="edit(item)"
          >
            <v-icon>
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
        </template>
        <template #label="{ item }">
          <v-text-field
            v-if="item.placeholder"
            v-model="newSubcategories[item.parentId]"
            class="mt-4"
            :placeholder="item.placeholder"
            single-line
            solo
            flat
            dense
            outlined
            clearable
            hint="Press enter to add"
            @keydown.enter="addSubcategory(item.parentId)"
          />
          <div v-else-if="editing === item.id">
            <v-text-field
              ref="editTextField"
              v-model="item.name"
              class="mt-4"
              single-line
              solo
              flat
              outlined
              dense
              hint="Press enter to save"
              @keydown.enter="saveEdit(item)"
            />
          </div>
          <div v-else>
            {{ item.name }}
          </div>
        </template>
        <template #append="{ item }">
          <v-btn
            v-if="item.created_by !== null"
            text
            icon
            @click="deleteCategory(item)"
          >
            <v-icon
              v-if="!item.placeholder"
            >
              {{ icons.mdiDelete }}
            </v-icon>
          </v-btn>
        </template>
      </v-treeview>
      <div class="inline-items">
        <v-icon class="mb-2 ml-2">
          {{ icons.mdiMenuRight }}
        </v-icon>
        <v-icon class="mb-2 ml-2">
          {{ icons.mdiPencil }}
        </v-icon>
        <v-text-field
          v-model="newCategory"
          placeholder="Add new category"
          class="mt-2 ml-1"
          solo
          clearable
          flat
          focused
          dense
          outlined
          hint="Press enter to add"
          @keydown.enter="addCategory(null)"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
import {
  mdiCancel, mdiPencil, mdiDelete, mdiPlus, mdiMenuRight,
} from '@mdi/js'
import axios from 'axios'
import TransactionCategorySettingShimmer from '@/components/shimmers/TransactionCategorySettingsShimmer.vue'

export default {
  components: {
    TransactionCategorySettingShimmer,
  },
  data() {
    return {
      categories: [],
      isTransactionCategoriesLoading: false,
      open: [],
      editing: null,
      newCategory: '',
      newSubcategories: {},
      nextId: 6,
      icons: {
        mdiCancel,
        mdiPencil,
        mdiDelete,
        mdiPlus,
        mdiMenuRight,
      },
    }
  },
  mounted() {
    this.getTransactionCategories()
  },
  methods: {
    getTransactionCategories() {
      this.isTransactionCategoriesLoading = true
      axios.get('transaction-categories')
        .then(response => {
          this.categories = response.data.map(category => ({
            id: category.id,
            name: category.name,
            created_by: category.created_by,
            transaction_sub_categories: [
              ...category.transaction_sub_categories.map(subcategory => ({
                id: subcategory.id,
                parentId: category.id,
                name: subcategory.name,
                created_by: subcategory.created_by,
              })),
              {
                parentId: category.id,
                placeholder: 'Add new subcategory',
              },
            ],
          }))
        }).finally(() => {
          this.isTransactionCategoriesLoading = false
        })
    },

    edit(item) {
      this.editing = item.id
      this.$nextTick(() => {
        this.$refs.editTextField.focus()
      })
    },
    saveEdit(item) {
      this.editing = null
      const apiUrl = this.getApiUrl(item)

      axios.put(`${apiUrl}/${item.id}`, {
        name: item.name,
      })
    },
    cancelEdit(item) {
      this.editing = null

      // Revert changes if necessary
    },
    deleteCategory(item) {
      this.removeItem(this.categories, item.id)

      const apiUrl = this.getApiUrl(item)
      axios.delete(`${apiUrl}/${item.id}`)
    },
    removeItem(items, id) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === id) {
          items.splice(i, 1)

          return
        } if (items[i].transaction_sub_categories) {
          this.removeItem(items[i].transaction_sub_categories, id)
        }
      }
    },
    addCategory(parentId) {
      const newCategoryObj = {
        id: this.nextId++,
        name: this.newCategory,
        created_by: this.nextId++,
        transaction_sub_categories: [
          {
            parentId: this.nextId - 1,
            placeholder: 'Add new subcategory',
          },
        ],
      }

      if (parentId === null) {
        this.categories.push(newCategoryObj)

        axios.post('transaction-categories', newCategoryObj)
          .then(response => {
            const createdCategory = response.data
            newCategoryObj.id = createdCategory.id

            // Update the parentId of the "Add new subcategory" item
            newCategoryObj.transaction_sub_categories[0].parentId = createdCategory.id
          })
          .catch(error => {
            console.error('Error creating category:', error)

            // Handle error, for example, remove the category from the list or show an error message
          })
      } else {
        this.addSubcategory(this.categories, parentId, newCategoryObj)
      }
      this.newCategory = ''
    },
    addSubcategory(parentId) {
      const newSubcategoryObj = {
        id: this.nextId++,
        name: this.newSubcategories[parentId],
        transaction_sub_categories: [],
        parentId,
      }

      const parentItem = this.findItemById(this.categories, parentId)
      if (parentItem) {
        parentItem.transaction_sub_categories.splice(parentItem.transaction_sub_categories.length - 1, 0, newSubcategoryObj)
        this.newSubcategories[parentId] = ''
      }

      // Add the axios.post call here
      axios.post(`transaction-categories/${parentId}/subcategories`, newSubcategoryObj)
        .then(response => {
          const createdSubcategory = response.data
          newSubcategoryObj.id = createdSubcategory.id
        })
        .catch(error => {
          console.error('Error creating subcategory:', error)

          // Handle error, for example, remove the subcategory from the list or show an error message
        })
    },
    findItemById(items, id) {
      for (const item of items) {
        if (item.id === id) {
          return item
        }

        if (item.transaction_sub_categories) {
          const foundItem = this.findItemById(item.transaction_sub_categories, id)
          if (foundItem) {
            return foundItem
          }
        }
      }

      return null
    },
    getApiUrl(item) {
      let apiUrl = 'transaction-categories'

      if (this.isSubcategory(item)) {
        apiUrl = `transaction-categories/${item.parentId}/subcategories`
      }

      return apiUrl
    },
    isSubcategory(item) {
      return item.hasOwnProperty('parentId')
    },
  },
}
</script>

<style>
  .inline-items {
    display: flex;
    align-items: center;
  }
</style>
